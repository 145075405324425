import React, {Component} from "react";
import css_classes from '../style.module.css';
import section3_img1 from '../images/section3_img1.png'
import section3_img2 from '../images/section3_img2.png'
import section3_img3 from '../images/section3_img3.png'
class Section3 extends Component{
    constructor(props) {
        super();
        this.name=props.name
    }
    render(props){
        return <div className={css_classes.section3}>
            <img src={section3_img1} className={css_classes.section3_img1}/>
            <div className={css_classes.section3_text}>
                <p>At a special press conference at the Royal Society in London, the Starmus Festival announced its new partnership with the prestigious Swiss watchmaker OMEGA.</p>
                <p>OMEGA’s own pioneering history is filled with scientific accomplishment including the conquests of space and the ocean, as well as revolutionary breakthroughs in magnetic watchmaking and precision.</p>
                <p>The 18K yellow gold watch presented to the winners, the OMEGA Speedmaster Moonwatch Professional Chronograph 42 mm, is distinguished by its unique caseback which includes the words “WINNER OF THE STEPHEN HAWKING MEDAL FOR SCIENCE COMMUNICATION”, as well as a laser-engraved medallion that follows the exact design of the medal itself</p>
            </div>
            <div className={'text-center'}>
                <img src={section3_img2} className={css_classes.section3_img2}/>
                <img src={section3_img3} className={css_classes.section3_img2}/>
            </div>
        </div>;
    }
}
export default Section3;