import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {Link} from "react-router-dom";
import PressItem from "../components/PressItem";
import StarmusSlider from "../components/Slider/StarmusSlider";

export class Page extends Component {

    constructor(props) {
        super();
        this.state={
            data:null
        }
        this.id=props.id;
        this.getData(this.id)
    }
    getData = (id)=> {

        fetch(`${Configs.api_url}data/get-press-details?id=${id}`)
            .then(response => response.json())
            .then(data => {
                        this.setState({
                            data:data.data.model,
                        });

                }
            );
    }
    render() {
        let random_min =1;
        let random_max = 3;
        let random_bg = Math.floor(Math.random() * (random_max - random_min + 1)) + random_min;


        if(this.state.data){
            return <React.Fragment>
                <div className={"am_wrapper page_main"}>
                    <div className={"header_section "+css_classes['random_section_'+random_bg]}>
                        <div className={'am_container'}>
                            <div className={'section_title '+css_classes.section_1_title}>{this.state.data.title}</div>
                        </div>
                    </div>
                    <div className={'section_60'}>
                        <div className={'am_container'}>
                            {/*<div className={'section_title black text_center '}>{this.state.data.title}</div>*/}
                            <div className={"text_1040 post_content"} dangerouslySetInnerHTML={{__html:this.state.data.content}}>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }
        return '';


    }
}
