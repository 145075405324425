import React, {Component} from 'react';
import styles from "../style.module.css";
import PreRegForm from "../../Starmus7/Sections/preRegForm";
import s6_1 from "../images/s6_1.png";
class S8Section6 extends Component {
    render() {
        return (
            <div className={styles.section}>
                <div className={'am_container flex flex_column_992 '+styles.revers_on_small}>
                    <div className={styles.text_section}>

                        <div className={styles.title_45+' color_orange'}>‘THE ISLAND OF STARS’</div>
                        <p className={styles.p}></p>
                        <p className={styles.p}>
                            La Palma proudly boasts one of the best observatories in the world, the  Roque de los Muchachos Observatory (ORM), at an altitude of 2,420  metres, which houses the largest optical-infrared telescope in the world  which, together with the other telescopes and other instruments at the  ORM, have been and are used to carry out astronomical studies,  achieving great advances in this field. In order to preserve both its privileged natural conditions and its low light  pollution, the island is protected as the first place in the world to be  declared a Starlight Reserve and Starlight Tourist destination. For this reason, in 2007 La Palma was the place chosen for the signing of  the Declaration in Defence of the Night Sky and the Right to Starlight,  today recognised as the 'La Palma Starlight Declaration'.
                        </p>
                        <p className={styles.p}>Find out more! <a href={"https://fundacionstarlight.org"} className={'color_blue'}> www.fundacionstarlight.org</a></p>

                    </div>
                    <div className={styles.img_section+' '+styles.img_section_right}>
                        <div className={styles.relative}>
                            <img  src={s6_1} alt={"s6_1"}/>
                            <div className={styles.img_copyright}>Photo: Daniel Lopez / IAC</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default S8Section6