import React, {Component} from "react";
import css_classes from '../style.module.css';

import StarmusSlider from "../../components/Slider/StarmusSlider";

class Section7 extends Component {
    constructor(props) {
        super();
    }

    render(props) {
        let slides = [];
        for(let i=-7;i<=20;i++){
                slides.push(<img src={require(`../images/carousel/${i}.jpg`)}/>)
        }
        return <div className={css_classes.section_7_slider+' contain_slider'}>

            <StarmusSlider>
                {slides}
            </StarmusSlider>
        </div>;
    }
}

export default Section7;