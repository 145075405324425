import React, {Component,useEffect} from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

class Content extends Component {
    constructor(props) {
        super(props);
        this.updateMenuClass = props.updateMenuClass;
    }
    static propTypes = {
        location: PropTypes.object.isRequired,
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            let location =this.props.location.pathname;
            if(['/'].includes(location)){
                this.updateMenuClass('dark new_menu');
            }else{
                this.updateMenuClass('dark');
            }
        }
    }

    onRouteChanged() {

    }
    render() {
        return (
            <div className={'main_content'}>{this.props.children}</div>
        );
    }

}
const ContentWithRouter = withRouter(Content);
export {Content,ContentWithRouter}