import React, {Component} from "react";
import css_classes from '../style.module.css';
class Section2 extends Component{
    constructor(props) {
        super();
        this.name=props.name
    }
    render(props){
        return <div className={css_classes.section2}>
            <div className={'am_container'}>
                <div className={" row "+css_classes.section2_container}>
                    <div className={'col6 '+css_classes.section2_left}></div>
                    <div className={'col6 '+css_classes.section2_content}>
                        <div className={"section_title "+css_classes.section2_title}>ABOUT MEDAL</div>
                        <p className={css_classes.section2_text}>Under the name of the greatest scientist of the 20th and early 21st centuries, Stephen Hawking, and sponsored by Starmus, the most ambitious Science festival of all time, the Stephen Hawking Science Medal is a prestigious award which recognizes the merit of popular science on an international level.</p>
                        <p className={css_classes.section2_text}>The design of the medal used a portrait of Professor Hawking by cosmonaut Alexei Leonov, the first man to perform a spacewalk and member of the Advisory Council Starmus since its first edition. The other side combines the image of Alexei Leonov of the first spacewalk and the iconic guitar – the “Red Special” – to demonstrate music, another major component of the Starmus Festival.</p>
                    </div>
                </div>
            </div>

        </div>;
    }
}
export default Section2;