import React, {Component} from "react";
import css_classes from '../style.module.css';
class Section1 extends Component{
    constructor(props) {
        super();
        this.name=props.name
    }
    render(props){
        return <div className={css_classes.section1}>
            <div className="am_container">
                <div className={css_classes.section1_title_group}>
                    <div className={"section_title "+css_classes.section1_title}>STEPHEN HAWKING MEDAL</div>
                    <div className="section_sub_title">FOR SCIENCE COMMUNICATION</div>
                </div>
            </div>
        </div>;
    }
}
export default Section1;