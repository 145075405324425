import React, {Component} from 'react'
import './index.css'
import starmusLogo from '../../assets/icons/starmus_logo.png'
import FaceBookIcon from '../../assets/icons/facebook_icon.png'
import TwitterIcon from '../../assets/icons/x_icon.png'
import LinkedIcon from '../../assets/icons/linkedin_icon.png'
import TiktokIcon from '../../assets/icons/tiktok_icon.png'
import InstagramIcon from '../../assets/icons/instagram_icon.png'
import YouTubeIcon from '../../assets/icons/youtube_icon.png'
import ArmeniaIcon from '../../assets/icons/armenia.jpg'
import legalPdf from '../../pdf/pdf3.pdf'
import statementPdf from '../../pdf/pdf2.pdf'
class Footer extends Component {
  render() {
    let date = new Date();
    let year = date.getFullYear();
    return (
      <section className='footer-section'>
        <div className='container'>
          <div className="footer-block starmus-block">

            <div className={'footer-flex'}>
              <div className='footer-component'>
                <a href={"/"}><img src={starmusLogo} className='footer-img' alt='Starmus Logo'/></a>
              </div>
              <div className='social-icons footer-component'>
                <a href={"https://www.facebook.com/StarmusFestival"} target="_blank" rel="noopener noreferrer">
                  <img src={FaceBookIcon} className='social-icon' alt='Facebook'/>
                </a>
                <a href={"https://twitter.com/starmusfestival"} target="_blank" rel="noopener noreferrer">
                  <img src={TwitterIcon} className='social-icon' alt='Twitter'/>
                </a>
                <a href={"https://www.instagram.com/starmus/"} target="_blank" rel="noopener noreferrer">
                  <img src={InstagramIcon} className='social-icon' alt='Instagram'/>
                </a>
                <a href={"https://www.youtube.com/user/starmusfest"} target="_blank" rel="noopener noreferrer">
                  <img src={YouTubeIcon} className='social-icon' alt='YouTube'/>
                </a>
                <a href={"https://www.linkedin.com/company/starmus-festival/"} target="_blank" rel="noopener noreferrer">
                  <img src={LinkedIcon} className='social-icon' alt='likedin'/>
                </a>
                <a href={"https://www.tiktok.com/@starmusfestival?_t=8lAfo1uRj3S&_r=1"} target="_blank" rel="noopener noreferrer">
                  <img src={TiktokIcon} className='social-icon' alt='Tiktok'/>
                </a>
              </div>
              <div className='footer-component'>
                <p className='copy-right-text starmus-p'>© {year} Starmus Festival</p>
              </div>
            </div>
          </div>
          <div className={'footer_links'}>
            <a href={legalPdf} target={"_blank"}>LEGAL NOTICE</a>
            <a href={statementPdf} target={"_blank"}>STARMUS Universe Privacy Statement</a>
          </div>
        </div>
      </section>
    )
  }
}

export default Footer

export class HomeFooter extends Component{
  render() {
    return <div className={'footer-honorary-div'}>
      <div className={'footer-honorary-p starmus-p'}>
        In cooperation with the Government of the Republic of Armenia
      </div>
      <div className={'armenia-logo'}><img src={ArmeniaIcon} alt='Armenia'/></div>
    </div>
  }
}