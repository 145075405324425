import {Component} from "react";
import React from "react";
import './index.css';
import {Link} from 'react-router-dom';

class MainMenu extends Component {
    constructor(props) {

      super();
      this.state={showMenu:false,menuClass:props.menuClass};
      this.toggleMenu = this.toggleMenu.bind(this);
      this.toggleSubMenu = this.toggleSubMenu.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({showMenu:false,menuClass:nextProps.menuClass})
    }
    let
    menuTree = [
        // {title: 'About', link: "/about-us"},
        // {title: 'STARMUS VII', link: "/Slovakia",extraClass:true},
        {
            key:'festivals',
            title: 'Festivals', items: [
                {title: "Starmus VII", link: "/festival/7"},
                {title: "Starmus VI", link: "/festival/6"},
                {title: "Starmus V", link: "/festival/5"},
                {title: "Starmus IV", link: "/festival/4"},
                {title: "Starmus III", link: "/festival/3"},
                {title: "Starmus II", link: "/festival/2"},
                {title: "Starmus I", link: "/festival/1"},
            ]
        },
        {title: 'Testimonials', link: "/testimonials"},
        {title: 'Speakers', link: "/speakers"},
        {title: 'Artists', link: "/artists"},
        {title: 'Stephen Hawking medal', link: "/stephen-hawking-medal"},
       /* {
            key:'location',
            title: 'Location', items: [
                {title: "Location Armenia", link: "/location"},
                {title: "Q & A", link: "/qa"},
            ]
        },*/
        // {title: 'Why Armenia', link: "/location"},
        {
            key:'press',
            title: 'Press', items: [
                {title: "News", link: "/press/news"},
                {title: "Blog", link: "/press/blog"},
                {title: "Media Coverage", link: "/media-coverage"},
            ]
        },
        {title: 'Contacts', link: "/contacts"},
        // {title: 'Buy Ticket', link: "/ticket/buy"},
    ];

    renderMenuTree(items) {
        let menu = items.map(function (item) {
            if (item.hasOwnProperty('items')) {

                return <div key={item.key} className={"menu-item"}><span className={'menu_label'} onClick={()=>this.toggleSubMenu(item.key)}>{item.title}</span><span className={"show_in_small has_sub_menu"}>&#8249;</span>
                    <div className={'sub-menu '+ (this.state.openedSubMenu==item.key?'open':'')}>{this.renderMenuTree(item.items)}</div>
                </div>
            } else {
                return <div key={item.link} className={"menu-item "+(item.extraClass?"extra":"")}>
                    <Link to={item.link} onClick={this.toggleMenu}>{item.title} {item.extraClass && <span className={"after"}></span>}</Link>
                </div>
            }
        }, this);
        return menu;
    }

    toggleMenu(){
       this.setState({showMenu:!this.state.showMenu,openedSubMenu:false})
    }
    toggleSubMenu(key){
        let openedSubMenu=this.state.openedSubMenu;
        if(openedSubMenu==key){
            this.setState({openedSubMenu:false});
        }else {
            this.setState({openedSubMenu:key});
        }
    }

    render() {

        return (
            <div id={'menu-header'} className={this.state.menuClass}>
                <div className={'main-menu am_container '}>
                    <Link to="/">
                        <div className='starmus-logo'/>
                    </Link>
                    <div className={'starmus-menu'+ (this.state.showMenu?' open':'')}>
                        {this.renderMenuTree(this.menuTree)}

                    </div>
                    <div id="menu_toggle" className={"show_in_small transition" + (this.state.showMenu?' open':'')} onClick={this.toggleMenu}>
                        <div className="burger-dots first-dot"></div>
                        <div className="burger-dots second-dot"></div>
                        <div className="burger-dots third-dot"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainMenu;