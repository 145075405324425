import React, {Component} from "react";
import css_classes from '../style.module.css';
import Figure from "../../components/Figure";
import Configs from "../../configs";
class Section5 extends Component{
    constructor(props) {
        super();
        this.state={
            board_members:[],
            past_board_members:[],
            advisors:[],
        }
    }
    componentDidMount() {
        this.getData();
    }
    getData = (festival_id=0)=> {
        fetch(`${Configs.api_url}data/get-board-members`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        board_members: data.data.board_members,
                        past_board_members: data.data.past_board_members,
                        advisors: data.data.advisors,
                    });
                }
            );
    }

    render(props){
        let board_members=[];
        let past_board_members=[];
        let advisors=[];
        this.state.board_members.map((e)=>(board_members.push(<Figure info={e}/>)));
        this.state.past_board_members.map((e)=>(past_board_members.push(<Figure info={e}/>)));
        this.state.advisors.map((e)=>(advisors.push(<Figure info={e}/>)));
        return <div className={"am_container  text_center "+css_classes.section5}>

            <div className="section_title black">STARMUS ADVISORY BOARD</div>
            <div className={css_classes.section_5_figures_list_1}>
                {board_members}
            </div>
            <div className={"section_title black "+css_classes.section5_title2}>PAST BOARD MEMBERS</div>
            <div className={css_classes.section_5_figures_list_2}>
                {past_board_members}
            </div>
            <div className={"section_title black "+css_classes.section5_title2}>Advisors</div>
            <div className={css_classes.section_5_figures_list_2}>
                {advisors}
            </div>
        </div>;
    }
}
export default Section5;