import React,{Component} from "react";
import css_classes from './style.module.css'
export class ProgramItem extends Component{
    constructor(props) {
        super();
        this.data=props.data;
        this.state={
            open:false
        };
        this.openInfo=this.openInfo.bind(this);
    }
    openInfo(){
        let open = this.state.open;
        this.setState({open: !open})
    }
    render() {
        let list = this.data.map((value)=>{
            if(!value.start_time){
                return '';
            }
            return (
                <div key={value.program_id} className={css_classes.program_day_row}>
                    <div className={css_classes.program_day_row_time}>{value.start_time+(value.end_time?' - '+value.end_time:'')}</div><div className={css_classes.program_day_row_info} dangerouslySetInnerHTML={{__html: value.info}}></div>
                </div>
            )
        })

        return <div className={css_classes.prgoram_day_wrap }>
            <div onClick={this.openInfo} className={css_classes.program_day_header}>
                <span className={css_classes.program_day_date }>{this.data[0].display_date}</span>
                {this.data[0].label!=''?<span className={css_classes.program_day_date_after}></span>:''}
                <span className={css_classes.program_day_name}>{this.data[0].label}</span>
                <span className={this.state.open?css_classes.accordion_close:css_classes.accordion_open}></span>
            </div>
            <div className={'transition '+css_classes.program_day_info+' '+(this.state.open?css_classes.program_day_info_open:'') }>
                {list}
            </div>

        </div>
    }
}